// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useState } from 'react';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Button from '@cloudscape-design/components/button';
import Icon from '@cloudscape-design/components/icon';
import Alert from '@cloudscape-design/components/alert';
import Modal from '@cloudscape-design/components/modal';
import Box from '@cloudscape-design/components/box';
import SpaceBetween from '@cloudscape-design/components/space-between';

function generateAlphabetArray(count) {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

  // Ensure count is within the range of 1 to 26 (number of letters in the alphabet)
  count = Math.max(1, Math.min(26, count));

  // Use map to convert each letter to uppercase
  return alphabet.slice(0, count).map(letter => letter.toUpperCase());
}

const ExportExcelAll = ({ sheets, fileName }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = () => {
    /* create workbook and append worksheet */
    const wb = XLSX.utils.book_new();

    let ws;
    for (let i = 0; i < sheets.length; i++) {
      const title = sheets[i].title;
      const data = sheets[i].data;
      ws = XLSX.utils.json_to_sheet(data);

      if (sheets[i].format) {
        /* assign number formats */
        const rowCount = data.length + 2;
        const colCount = Object.keys(data).length + 2;
        const cols = generateAlphabetArray(colCount);

        for (let j = 2; j < rowCount; j++) {
          for (let k = 0; k < colCount; k++) {
            ws[`${cols[k]}${j}`].z = '"$"#,##0.00_);\\("$"#,##0.00\\)'; // Currency format
          }
        }
      }

      XLSX.utils.book_append_sheet(wb, ws, title);
    }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const [showModal, setShowDeleteModal] = useState();
  const onExport = () => setShowDeleteModal(true);

  return (
    <>
      <Button variant="primary" onClick={onExport} data-testid="submit">
        <Icon name="download" />
        &nbsp;Export (.xls)
      </Button>

      <Modal
        onDismiss={() => setShowDeleteModal(false)}
        visible={showModal}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={() => setShowDeleteModal(false)}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  exportToExcel(fileName);
                  setShowDeleteModal(false);
                }}
              >
                <Icon name="download" />
                &nbsp;Export (.xls)
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Export estimate"
      >
        <Alert statusIconAriaLabel="Info">
          3-year contracts are not yet available through the AWS console but can be obtained by contacting AWS.
          <br />
          <br />
          For any questions on this estimate, please send an email to:
          <br />
          <strong>
            <a href="mailto:aws-redhat-partnerteam@amazon.com">aws-redhat-partnerteam@amazon.com</a>
          </strong>
        </Alert>
      </Modal>
    </>
  );
};

export default ExportExcelAll;
