// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
export const LABEL_SCENARIO_LIKEFORLIKE_AMD64 = 'Like for Like VM count (amd64)';
export const LABEL_SCENARIO_BINPACK_AMD64 = 'Binpack (amd64)';
export const LABEL_SCENARIO_LIKEFORLIKE_GRAVITON_NONMETAL = 'Like for Like VM count (AWS Graviton non Bare Metal)';
export const LABEL_SCENARIO_BINPACK_GRAVITON_NONMETAL = 'Binpack (AWS Graviton non Bare Metal)';
export const LABEL_SCENARIO_VIRTUALIZATION_NONGRAVITON = 'ROSA Virtualization (Amazon EC2 Bare Metal x86)';

const HOURS_PER_MONTH = 730;
export const rosaWorkerFeesOnDemand = 0.171; // 1500/12/HOURS_PER_MONTH
export const rosaWorkerFees1year = 1000 / 12 / HOURS_PER_MONTH;
export const rosaWorkerFees3year = 667 / 12 / HOURS_PER_MONTH;
