// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

export const instancesFetch = async () => {
  try {
    const response = await fetch('data/rosa-ec2-full.json');
    const instanceData = await response.json();

    return instanceData.map(instance => {
      instance['count'] = 0;
      return instance;
    });
  } catch (error) {
    console.error('Error loading JSON:', error);
    throw error;
  }
};

export const getInstances = async () => {
  return (await instancesFetch()).sort((a, b) => {
    if (a.cpu.value !== b.cpu.value) {
      return a.cpu.value - b.cpu.value; // Sort by CPU
    }
    if (a.memory.value !== b.memory.value) {
      return a.memory.value - b.memory.value; // Sort by memory if CPU is the same
    }
    return a.name.localeCompare(b.name); // Sort by name if CPU and memory are the same
  });
};

export const instances = await getInstances();
