// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React, { useLayoutEffect, useState } from 'react';
import Alert from '@cloudscape-design/components/alert';

import { getCheaperUnitPricesVariants } from '../../lib/cost';

export function BestPriceSummary(props) {
  const { tableItems, ec2Prices } = props;
  const [items, setItems] = useState();
  const [count, setCount] = useState();

  useLayoutEffect(() => {
    const items = (tableItems || []).filter(item => item.count > 0);
    let count = 0;
    (items || []).map(node => (count += getCheaperUnitPricesVariants(ec2Prices, node.id).length - 1));

    setCount(count);
    setItems(items);
  }, [ec2Prices, tableItems]);

  return (
    count > 0 && (
      <Alert statusIconAriaLabel="Info" header="Price-performance recommendations">
        <br />
        Instance types for best price performance or lowest price:
        {(items || []).map(node => (
          <ul key={`${node.id}-a`}>
            {getCheaperUnitPricesVariants(ec2Prices, node.id).length > 1 && (
              <li>
                Alternative(s) to currently selected <strong>{node.id}</strong> (lowest to highest price):
                <ol>
                  {getCheaperUnitPricesVariants(ec2Prices, node.id).map(ec2 => (
                    <li key={`${ec2.type}-b`}>
                      {ec2.type} - {ec2.priceOnDemand}/Hour - {ec2.vcpu} vCPUs, {ec2.memory} Memory
                    </li>
                  ))}
                </ol>
              </li>
            )}
          </ul>
        ))}
      </Alert>
    )
  );
}
