// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
/* eslint-disable prettier/prettier */

const infoFetch = async () => {
    try {
        const response = await fetch('prices/info.json');
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error loading JSON:', error);
        throw error;
    }
};

export const info = await infoFetch();
