// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

export type ServerInputData = {
  vcpu: number;
  vcpuUtil: number;
  memory: number;
  memUtil: number;
  storage: number;
  storageUtil: number;
};

export type RatioData = { vcpu: number; memory: number; storage: number };

export type SummaryDataOutput = {
  totalVCPUs: number;
  totalMemory: number;
  totalStorage: number;
  avgVCPUs: number;
  avgMemory: number;
  avgStorage: number;
};

export function calculateSummary(data: ServerInputData[]): SummaryDataOutput {
  const totalVCPUs = data.reduce((sum: number, server: ServerInputData) => {
    if (server.vcpu === undefined) {
      return sum;
    }
    return sum + server.vcpu;
  }, 0);
  const totalMemory = data.reduce((sum: number, server: ServerInputData) => {
    if (server.memory === undefined) {
      return sum;
    }
    return sum + server.memory;
  }, 0);
  const totalStorage = data.reduce((sum: number, server: ServerInputData) => {
    if (server.storage === undefined) {
      return sum;
    }
    return sum + server.storage;
  }, 0);
  const avgVCPUs = data.reduce((sum: number, server: ServerInputData) => {
    if (server.vcpu === undefined || server.vcpuUtil === undefined) {
      return sum;
    }
    return sum + server.vcpu * server.vcpuUtil;
  }, 0);

  const avgMemory = data.reduce((sum: number, server: ServerInputData) => {
    if (server.memory === undefined || server.memUtil === undefined) {
      return sum;
    }
    return sum + server.memory * server.memUtil;
  }, 0);
  const avgStorage = data.reduce((sum: number, server: ServerInputData) => {
    if (server.storage === undefined || server.storageUtil === undefined) {
      return sum;
    }
    return sum + server.storage * server.storageUtil;
  }, 0);

  return {
    totalVCPUs: Math.ceil(totalVCPUs),
    totalMemory: Math.ceil(totalMemory),
    totalStorage: Math.ceil(totalStorage),
    avgVCPUs: Math.ceil(avgVCPUs),
    avgMemory: Math.ceil(avgMemory),
    avgStorage: Math.ceil(avgStorage),
  };
}

export function deduplicateRatioData(data: RatioData[]): RatioData[] {
  const uniqueMap = new Map();

  return data.filter(item => {
    const key = `${item.vcpu}-${item.memory}-${item.storage}`;
    if (!uniqueMap.has(key)) {
      uniqueMap.set(key, true);
      return true;
    }
    return false;
  });
}

type Suggestion = {
  vcpu: number;
  memory: number;
  storage: number;
};

type SuggestionOutput = {
  suggestions: Suggestion[];
  totalSuggestedVCPUs: number;
  totalSuggestedMemory: number;
  totalSuggestedStorage: number;
  vcpuDiff: number;
  memoryDiff: number;
  storageDiff: number;
};

export function createLikeForLikeInstancesSuggestions(
  data: ServerInputData[],
  ratioData: RatioData[]
): SuggestionOutput {
  let totalSuggestedVCPUs = 0;
  let totalSuggestedMemory = 0;
  let totalSuggestedStorage = 0;

  const totalInputVCPUs = data.reduce((sum: number, server: ServerInputData) => sum + server.vcpu, 0);
  const totalInputMemory = data.reduce((sum: number, server: ServerInputData) => sum + server.memory, 0);

  const suggestions: { vcpu: number; memory: number; storage: number }[] = [];

  data.forEach((server: ServerInputData) => {
    const avgVCPUs = server.vcpu * server.vcpuUtil;
    const avgMemory = server.memory * server.memUtil;

    const bestMatch = ratioData.reduce((best: RatioData, current: RatioData) => {
      const vCPUDiff = Math.abs(current.vcpu - avgVCPUs);
      const memoryDiff = Math.abs(current.memory - avgMemory);
      const currentDiff = vCPUDiff + memoryDiff;
      const bestDiff = Math.abs(best.vcpu - avgVCPUs) + Math.abs(best.memory - avgMemory);
      return currentDiff < bestDiff ? current : best;
    });

    totalSuggestedVCPUs += bestMatch.vcpu;
    totalSuggestedMemory += bestMatch.memory;
    totalSuggestedStorage += bestMatch.storage;

    suggestions.push({
      vcpu: bestMatch.vcpu,
      memory: bestMatch.memory,
      storage: bestMatch.storage,
    });
  });

  // Calculate totals from input data
  const totalInputStorage = data.reduce((sum: any, server: { storage: any }) => sum + server.storage, 0);

  const vcpuDiff = totalSuggestedVCPUs - totalInputVCPUs;
  const memoryDiff = totalSuggestedMemory - totalInputMemory;
  const storageDiff = totalSuggestedStorage - totalInputStorage;

  return {
    suggestions,
    totalSuggestedVCPUs,
    totalSuggestedMemory,
    totalSuggestedStorage,
    vcpuDiff,
    memoryDiff,
    storageDiff,
  };
}

export function createBinpackInstancesSuggestions(summary: SummaryDataOutput, ratioData: any[]): SuggestionOutput {
  let remainingVCPUs = summary.avgVCPUs;
  let remainingMemory = summary.avgMemory;
  const suggestions = [];

  const totalRequiredVCPUs = summary.avgVCPUs;

  while (remainingVCPUs > 0 || remainingMemory > 0) {
    const bestMatch = ratioData.reduce(
      (best: { vcpu: number; memory: number }, current: { vcpu: number; memory: number }) => {
        if (current.vcpu > remainingVCPUs || current.memory > remainingMemory) {
          return best;
        }
        const vCPUEfficiency = current.vcpu / remainingVCPUs;
        const memoryEfficiency = current.memory / remainingMemory;
        const currentEfficiency = (vCPUEfficiency + memoryEfficiency) / 2;
        const bestEfficiency = (best.vcpu / remainingVCPUs + best.memory / remainingMemory) / 2;

        return currentEfficiency > bestEfficiency ? current : best;
      },
      ratioData[0]
    );

    suggestions.push(bestMatch);
    remainingVCPUs -= bestMatch.vcpu;
    remainingMemory -= bestMatch.memory;
  }

  // Calculate total recommended vCPUs
  let totalSuggestedVCPUs = suggestions.reduce((sum, instance) => sum + instance.vcpu, 0);

  // Add more instances if we don't meet the minimum required vCPUs
  while (totalSuggestedVCPUs < totalRequiredVCPUs) {
    const smallestInstance = ratioData.reduce((min: { vcpu: number }, current: { vcpu: number }) =>
      current.vcpu < min.vcpu ? current : min
    );
    suggestions.push(smallestInstance);
    totalSuggestedVCPUs += smallestInstance.vcpu;
  }

  // Add more instances if we don't meet the minimum required memory
  let totalSuggestedMemory = suggestions.reduce((sum, instance) => sum + instance.memory, 0);
  while (totalSuggestedMemory < summary.avgMemory) {
    const smallestMemoryInstance = ratioData.reduce((min: { memory: number }, current: { memory: number }) =>
      current.memory < min.memory ? current : min
    );
    suggestions.push(smallestMemoryInstance);
    totalSuggestedMemory += smallestMemoryInstance.memory;
  }

  const totalSuggestedStorage = suggestions.reduce((sum, instance) => sum + instance.storage, 0);

  const vcpuDiff = totalSuggestedVCPUs - summary.avgVCPUs;
  const memoryDiff = totalSuggestedMemory - summary.avgMemory;
  const storageDiff = totalSuggestedStorage - summary.avgStorage;

  return {
    suggestions,
    totalSuggestedVCPUs,
    totalSuggestedMemory,
    totalSuggestedStorage,
    vcpuDiff,
    memoryDiff,
    storageDiff,
  };
}
