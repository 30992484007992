/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_mobile_analytics_app_id": "04414f29b53d4460bf044f69826d3876",
    "aws_mobile_analytics_app_region": "ap-southeast-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "04414f29b53d4460bf044f69826d3876",
            "region": "ap-southeast-2"
        }
    },
    "aws_cognito_identity_pool_id": "ap-southeast-2:47a1818c-d5b7-45cd-8dae-b82ae4913d1a",
    "aws_cognito_region": "ap-southeast-2",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": []
};


export default awsmobile;
