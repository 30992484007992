// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React, { useLayoutEffect, useState } from 'react';
import { Input, Button, Grid, Box, Select, FormField } from '@cloudscape-design/components';

const KeyValueForm = ({ onDiscountCostChange }) => {
  const [keyValuePairs, setKeyValuePairs] = useState([]);

  useLayoutEffect(() => {
    // set extras if present in the URL
    const searchParams = new URLSearchParams(location.search);
    const discountCostParam = searchParams.get('extras');
    if (discountCostParam) {
      setKeyValuePairs(JSON.parse(discountCostParam));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleKeyChange = (index, e) => {
    const newKeyValuePairs = [...keyValuePairs];
    newKeyValuePairs[index].key = e.detail.value;
    setKeyValuePairs(newKeyValuePairs);
    onDiscountCostChange(keyValuePairs.filter(pair => pair.key.length >= 5 && isValidValue(pair.value)));
  };

  const handleValueChange = (index, e) => {
    const newKeyValuePairs = [...keyValuePairs];
    newKeyValuePairs[index].value = e.detail.value;
    setKeyValuePairs(newKeyValuePairs);
    onDiscountCostChange(keyValuePairs.filter(pair => pair.key.length >= 5 && isValidValue(pair.value)));
  };

  const handleDiscountTypeChange = (index, e) => {
    const newKeyValuePairs = [...keyValuePairs];
    newKeyValuePairs[index].discountType = e.detail.selectedOption.value;
    setKeyValuePairs(newKeyValuePairs);
    onDiscountCostChange(keyValuePairs.filter(pair => pair.key.length >= 5 && isValidValue(pair.value)));
  };

  const handlePeriodChange = (index, e) => {
    const newKeyValuePairs = [...keyValuePairs];
    newKeyValuePairs[index].period = e.detail.selectedOption.value;
    setKeyValuePairs(newKeyValuePairs);
    onDiscountCostChange(keyValuePairs.filter(pair => pair.key.length >= 5 && isValidValue(pair.value)));
  };

  const handleAppliesToChange = (index, e) => {
    const newKeyValuePairs = [...keyValuePairs];
    newKeyValuePairs[index].appliesTo = e.detail.selectedOption.value;

    // If "Additional discount or cost" is selected, set period to "Annual" and discountType to "amount"
    if (e.detail.selectedOption.value === 'Additional discount or cost') {
      newKeyValuePairs[index].period = 'Annual';
      newKeyValuePairs[index].discountType = 'amount';
    }

    setKeyValuePairs(newKeyValuePairs);
    onDiscountCostChange(keyValuePairs.filter(pair => pair.key.length >= 5 && isValidValue(pair.value)));
  };

  const handleTypeChange = (index, e) => {
    const newKeyValuePairs = [...keyValuePairs];
    newKeyValuePairs[index].type = e.detail.selectedOption.value;
    setKeyValuePairs(newKeyValuePairs);
    onDiscountCostChange(keyValuePairs.filter(pair => pair.key.length >= 5 && isValidValue(pair.value)));
  };

  const handleAddKeyValue = () => {
    setKeyValuePairs([
      ...keyValuePairs,
      {
        type: 'Discount',
        key: '',
        value: '',
        discountType: 'percentage',
        period: 'Annual',
        appliesTo: 'Red Hat Data Plane Fee',
      },
    ]);
    onDiscountCostChange(keyValuePairs.filter(pair => pair.key.length >= 5 && isValidValue(pair.value)));
  };

  const handleDeleteKeyValue = index => {
    const newKeyValuePairs = [...keyValuePairs];
    newKeyValuePairs.splice(index, 1);
    setKeyValuePairs(newKeyValuePairs);
    onDiscountCostChange(newKeyValuePairs.filter(pair => pair.key.length >= 5 && isValidValue(pair.value)));
  };

  const isValidValue = value => {
    return !isNaN(value) || value === '';
  };

  const handleLoadTemplate = template => {
    let newKeyValuePairs = [...keyValuePairs];

    switch (template) {
      case 'AWS Marketplace Private Offers Discount':
        newKeyValuePairs.push({
          type: 'Discount',
          key: 'AWS Marketplace Private Offer',
          value: '',
          discountType: 'percentage',
          period: 'Annual',
          appliesTo: 'Red Hat Data Plane Fee',
        });
        break;
      case 'AWS and Red Hat PoC Credits Discount':
        newKeyValuePairs.push(
          {
            type: 'Discount',
            key: 'PoC Credits - Red Hat Data Plane Fee',
            value: '0',
            discountType: 'amount',
            period: 'First Year',
            appliesTo: 'Red Hat Data Plane Fee',
          },
          {
            type: 'Discount',
            key: 'PoC Credits - AWS Infrastructure',
            value: '0',
            discountType: 'amount',
            period: 'First Year',
            appliesTo: 'Additional discount or cost',
          }
        );
        break;
      case 'Non-ROSA OpenShift workloads to ROSA Discount':
        newKeyValuePairs.push({
          type: 'Discount',
          key: 'Non-ROSA OpenShift workloads to ROSA Discount',
          value: '75',
          discountType: 'percentage',
          period: 'First Year',
          appliesTo: 'Red Hat Data Plane Fee',
        });
        break;
      case 'AWS Enterprise Support':
        newKeyValuePairs.push({
          type: 'Cost',
          key: 'AWS Enterprise Support',
          value: '',
          discountType: 'amount',
          period: 'Annual',
          appliesTo: 'Additional discount or cost',
        });
        break;
      case 'AWS Business Support':
        newKeyValuePairs.push({
          type: 'Cost',
          key: 'AWS Business Support',
          value: '',
          discountType: 'amount',
          period: 'Annual',
          appliesTo: 'Additional discount or cost',
        });
        break;
      case 'Amazon Elastic Block Store (EBS) Volume':
        newKeyValuePairs.push({
          type: 'Cost',
          key: 'Amazon Elastic Block Store (EBS) Volume',
          value: '',
          discountType: 'amount',
          period: 'Annual',
          appliesTo: 'Additional discount or cost',
        });
        break;
      default:
        break;
    }

    setKeyValuePairs(newKeyValuePairs);
  };

  return (
    <Box>
      <Select
        options={[
          { label: 'AWS Marketplace Private Offers Discount', value: 'AWS Marketplace Private Offers Discount' },
          { label: 'AWS and Red Hat PoC Credits Discount', value: 'AWS and Red Hat PoC Credits Discount' },
          {
            label: 'Non-ROSA OpenShift workloads to ROSA Discount',
            value: 'Non-ROSA OpenShift workloads to ROSA Discount',
          },
          { label: 'AWS Enterprise Support', value: 'AWS Enterprise Support' },
          { label: 'AWS Business Support', value: 'AWS Business Support' },
        ]}
        onChange={e => handleLoadTemplate(e.detail.selectedOption.value)}
        label="Load template"
      />
      <br />
      {keyValuePairs.map((pair, index) => (
        <Grid
          key={index}
          gridDefinition={[
            { colspan: { default: 2 } },
            { colspan: { default: 3 } },
            { colspan: { default: 2 } },
            { colspan: { default: 1 } },
            { colspan: { default: 1 } },
            { colspan: { default: 2 } },
            { colspan: { default: 1 } },
          ]}
        >
          <FormField
            constraintText="Discount or Cost"
            errorText={pair.type.trim().length === 0 ? 'Type cannot be empty.' : ''}
          >
            <Select
              options={[
                { label: 'Discount', value: 'Discount' },
                { label: 'Cost', value: 'Cost' },
              ]}
              selectedOption={{ label: pair.type, value: pair.type }}
              onChange={e => handleTypeChange(index, e)}
            />
          </FormField>
          <FormField
            constraintText="Description"
            errorText={pair.key.length < 5 ? 'Description must be at least 5 characters.' : ''}
          >
            <Input
              value={pair.key}
              onChange={e => handleKeyChange(index, e)}
              placeholder="Enter description (eg, Discount A, Cost B)"
              invalid={pair.key.length < 5}
              minLength={5}
              autoFocus={index === 0}
              inputMode="text"
            />
          </FormField>
          <FormField
            constraintText="Amount or percentage"
            errorText={pair.value.trim().length === 0 ? 'Empty value.' : ''}
          >
            <Input
              value={pair.value}
              onChange={e => handleValueChange(index, e)}
              placeholder="Enter amount or %"
              invalid={!isValidValue(pair.value)}
              inputMode="numeric"
            />
          </FormField>
          <FormField
            constraintText="Calculation method"
            errorText={pair.discountType.trim().length === 0 ? 'Calculation cannot be empty.' : ''}
          >
            <Select
              options={[
                { label: 'Amount', value: 'amount' },
                { label: '%', value: 'percentage' },
              ]}
              selectedOption={{ label: pair.discountType === 'amount' ? 'Amount' : '%', value: pair.discountType }}
              onChange={e => handleDiscountTypeChange(index, e)}
              disabled={pair.appliesTo === 'Additional discount or cost'}
            />
          </FormField>
          <FormField
            constraintText="Period"
            errorText={pair.period.trim().length === 0 ? 'Period cannot be empty.' : ''}
          >
            <Select
              options={[
                { label: 'Annual', value: 'Annual' },
                { label: 'First Year', value: 'First Year' },
              ]}
              selectedOption={{ label: pair.period, value: pair.period }}
              onChange={e => handlePeriodChange(index, e)}
            />
          </FormField>
          <FormField
            constraintText="Cost component to which the discount or cost applies"
            errorText={pair.appliesTo.trim().length === 0 ? 'Applies to cannot be empty.' : ''}
          >
            <Select
              options={[
                { label: 'Red Hat Data Plane Fee', value: 'Red Hat Data Plane Fee' },
                { label: 'Additional discount or cost', value: 'Additional discount or cost' },
              ]}
              selectedOption={{ label: pair.appliesTo, value: pair.appliesTo }}
              onChange={e => handleAppliesToChange(index, e)}
            />
          </FormField>
          <Button onClick={() => handleDeleteKeyValue(index)}>Delete</Button>
        </Grid>
      ))}
      <Button onClick={handleAddKeyValue}>Add</Button>
    </Box>
  );
};

export default KeyValueForm;
