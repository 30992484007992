// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React, { useLayoutEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import Container from '@cloudscape-design/components/container';
import ExpandableSection from '@cloudscape-design/components/expandable-section';
import FormField from '@cloudscape-design/components/form-field';
import { Select, SpaceBetween } from '@cloudscape-design/components';
import { regions } from '../../data/regions';
import {
  calculateSummary,
  createLikeForLikeInstancesSuggestions,
  createBinpackInstancesSuggestions,
  deduplicateRatioData,
} from '../../lib/import';
import { getEstimate, getWorkerNodes } from '../../lib/cost';
import { buildURLCheapestInstances, getCheapestInstances } from '../../lib/url';
import Tabs from '@cloudscape-design/components/tabs';
import Alert from '@cloudscape-design/components/alert';
import Icon from '@cloudscape-design/components/icon';
import Link from '@cloudscape-design/components/link';
import Grid from '@cloudscape-design/components/grid';
import { getInstances } from '../../data/instances';
import { rosaWorkerFeesOnDemand, rosaWorkerFees1year, rosaWorkerFees3year } from '../constants';
import Suggestions from './Suggestions';
import Summary from './Summary';
import {
  LABEL_SCENARIO_BINPACK_GRAVITON_NONMETAL,
  LABEL_SCENARIO_VIRTUALIZATION_NONGRAVITON,
  LABEL_SCENARIO_LIKEFORLIKE_AMD64,
  LABEL_SCENARIO_BINPACK_AMD64,
  LABEL_SCENARIO_LIKEFORLIKE_GRAVITON_NONMETAL,
} from '../constants';

const EXPECTED_COLUMNS = [
  'vCPU (Count)',
  'vCPU Usage (%)',
  'Memory Size (GiB)',
  'Memory Usage (%)',
  'Provisioned Storage (GB)',
  'Storage Usage (%)',
];

const regionLabels = regions.map(region => {
  return `${region.full_name} - ${region.code}`;
});

const defaultRegion = { value: regionLabels[0].code, label: regionLabels[0].label };
const selectRegion = prepareSelectRegion('region', defaultRegion);

function prepareSelectRegion(field, defaultOption) {
  const optionSet = [];
  // Building a non redundant list of the field passed as parameter.

  regionLabels.forEach(item => {
    optionSet.push(item);
  });
  optionSet.sort();

  // The first element is the default one.
  const options = [defaultOption];

  // Adding the other element ot the list.
  regions.forEach(region => {
    options.push({ label: `${region.full_name} - ${region.code}`, value: region.code });
  });
  return options;
}
function cleanupExcelSheetData(rawData) {
  return rawData
    .map(([vcpu, vcpuUtil, memory, memUtil, storage, storageUtil]) => ({
      vcpu,
      vcpuUtil,
      memory,
      memUtil,
      storage,
      storageUtil,
    }))
    .slice(1)
    .filter(obj => {
      return (
        obj.vcpu !== undefined &&
        obj.vcpuUtil !== undefined &&
        obj.memory !== undefined &&
        obj.memUtil !== undefined &&
        obj.storage !== undefined
      );
    });
}

const ExcelImporter = () => {
  const [sheets, setSheets] = useState([]);
  const [error, setError] = useState('');
  const [region, setRegion] = useState(defaultRegion);
  const [ebsPrices, setEBSPrices] = useState();
  const [ec2Prices, setEC2Prices] = useState();
  const [showAdditionalComponent, setShowAdditionalComponent] = useState(false);

  const handleCheckboxConfirm = () => {
    setShowAdditionalComponent(true);
  };

  useLayoutEffect(() => {
    if (!region.value) {
      return;
    }
    fetch(`/prices/${region.value}-ebs.json`)
      .then(res => res.json(), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then(
        resultEbs => {
          setEBSPrices(resultEbs);

          fetch(`/prices/${region.value}-ec2.json`)
            .then(res => res.json(), {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            })
            .then(
              resultEC2 => {
                setEC2Prices(resultEC2);
              },
              error => {
                setError(error);
              }
            );
        },
        error => {
          setError(error);
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region, setRegion]);

  useLayoutEffect(() => {
    if (!region.value) {
      return;
    }
    fetch(`/prices/${region.value}-ebs.json`)
      .then(res => res.json(), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then(
        resultEbs => {
          setEBSPrices(resultEbs);

          fetch(`/prices/${region.value}-ec2.json`)
            .then(res => res.json(), {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            })
            .then(
              resultEC2 => {
                setEC2Prices(resultEC2);
              },
              error => {
                setError(error);
              }
            );
        },
        error => {
          setError(error);
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region, setRegion]);

  const validateSheetIsNotEmpty = (sheetName, data) => {
    if (data.length <= 1) {
      console.error(`Empty sheet "${sheetName}".`);
    }

    return data.length > 1;
  };

  const validateSheetFormat = (sheetName, data) => {
    if (data.length === 0) {
      console.error(`No data found in sheet "${sheetName}". Data: "${data}".`);

      return false;
    }

    try {
      const headers = data[0].map(header => header.toLowerCase().trim());

      const result = EXPECTED_COLUMNS.every((col, index) => {
        return col.toLowerCase().trim() === headers[index];
      });
      if (!result) {
        console.error(`Invalid columns in sheet "${sheetName}".`);
      }
      return result;
    } catch (e) {
      console.error(`Invalid sheet "${sheetName}". Error: "${e}"`);
      return false;
    }
  };

  const handleFileUpload = e => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async event => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: 'binary' });

      const allInstances = await getInstances();

      const sheetData = workbook.SheetNames.map(sheetName => {
        const processEntry = ({ vcpu, memory }) => ({
          vcpu: Number(vcpu),
          memory: Number(memory.replace(' GiB', '')),
          storage: 128,
        });

        function findMetadata(instanceType) {
          return allInstances.find(meta => meta.id === instanceType);
        }

        function getEntriesWithVCPULessThan4(entries) {
          return entries.filter(entry => entry.vcpu < 4);
        }

        function convertToExpectedInstances(inputArray) {
          return Object.entries(inputArray).flatMap(([instanceType, count]) => Array(count).fill([instanceType]));
        }

        const noDiscounts = [];
        const sheet = workbook.Sheets[sheetName];
        const rawData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const data = cleanupExcelSheetData(rawData);

        const summary = calculateSummary(data);

        // Filter functions
        const metalNongravitonInstances = ec2Prices.filter(instance => {
          const metadata = findMetadata(instance.type);
          return metadata && metadata.size === 'metal' && metadata.architecture === 'amd64';
        });
        const x86Instances = ec2Prices.filter(instance => {
          const metadata = findMetadata(instance.type);
          return (
            metadata &&
            metadata.size !== 'metal' &&
            metadata.architecture === 'amd64' &&
            metadata.category !== 'accelerated_computing'
          );
        });
        const gravitonInstances = ec2Prices.filter(instance => {
          const metadata = findMetadata(instance.type);
          return metadata && metadata.size !== 'metal' && metadata.architecture === 'arm64';
        });

        // const gpuInstances = ec2Prices.filter(instance => {
        //   const metadata = findMetadata(instance.type);
        //   return metadata && metadata.category === 'accelerated_computing';
        // });

        // Ratios
        const x86InstancesRatioData = deduplicateRatioData(x86Instances.map(processEntry));
        const gravitonInstancesRatioData = deduplicateRatioData(gravitonInstances.map(processEntry));
        const metalNongravitonInstancesRatioData = deduplicateRatioData(metalNongravitonInstances.map(processEntry));

        // Instance recommendations
        const likeForLikeX86Instances = createLikeForLikeInstancesSuggestions(data, x86InstancesRatioData);
        const cheapeastPricesEC2LikeForLikeX86Instances = getCheapestInstances(
          x86Instances,
          likeForLikeX86Instances.suggestions
        );
        const binpackX86Instances = createBinpackInstancesSuggestions(summary, x86InstancesRatioData);
        const cheapeastBinpackX86Instances = getCheapestInstances(x86Instances, binpackX86Instances.suggestions);
        const likeForLikeGravitonInstances = createLikeForLikeInstancesSuggestions(data, gravitonInstancesRatioData);
        const cheapestLikeForLikeGravitonInstances = getCheapestInstances(
          gravitonInstances,
          likeForLikeGravitonInstances.suggestions
        );
        const binpackGravitonInstances = createBinpackInstancesSuggestions(summary, gravitonInstancesRatioData);
        const cheapestBinpackGravitonInstances = getCheapestInstances(
          gravitonInstances,
          binpackGravitonInstances.suggestions
        );
        const binpackMetalNonGravitonInstances = createBinpackInstancesSuggestions(
          summary,
          metalNongravitonInstancesRatioData
        );
        const cheapestBinpackMetalNonGravitonInstances = getCheapestInstances(
          metalNongravitonInstances,
          binpackMetalNonGravitonInstances.suggestions
        );

        // Cost Estimates

        function buildCostEstimate(instanceSuggestions, ec2Prices) {
          const localNodes = [...[['ec2_type']], ...convertToExpectedInstances(instanceSuggestions)];

          const workerNodesROSA = getWorkerNodes(
            localNodes,
            ec2Prices,
            rosaWorkerFeesOnDemand,
            rosaWorkerFees1year,
            rosaWorkerFees3year
          );
          const estimate = getEstimate(workerNodesROSA, 0, ec2Prices, ebsPrices, 0.25, noDiscounts);

          return {
            onDemand1Y: estimate.estimateTotalWithDiscountsOrAdditionalCosts.onDemand.annual,
            savingsPlanComputeNoUpfront1y:
              estimate.estimateTotalWithDiscountsOrAdditionalCosts.savingsPlanComputeNoUpfront1y.annually,
            savingsPlanComputeNoUpfront3y:
              estimate.estimateTotalWithDiscountsOrAdditionalCosts.savingsPlanComputeNoUpfront3y.annually,
          };
        }
        const priceLikeForLikeX86Instances = buildCostEstimate(cheapeastPricesEC2LikeForLikeX86Instances, ec2Prices);
        const priceBinpackX86Instances = buildCostEstimate(cheapeastBinpackX86Instances, ec2Prices);
        const priceLikeForLikeGravitonInstances = buildCostEstimate(cheapestLikeForLikeGravitonInstances, ec2Prices);
        const priceBinpackGravitonInstances = buildCostEstimate(cheapestBinpackGravitonInstances, ec2Prices);
        const priceBinpackMetalNonGravitonInstances = buildCostEstimate(
          cheapestBinpackMetalNonGravitonInstances,
          ec2Prices
        );

        return {
          name: sheetName,
          data: rawData,
          countEntriesWithVCPULessThan4: getEntriesWithVCPULessThan4(data).length,
          summary,
          suggestions: {
            likeForLikeX86Instances,
            urlLikeForLikeX86Instances: buildURLCheapestInstances(
              region.value,
              cheapeastPricesEC2LikeForLikeX86Instances
            ),
            priceLikeForLikeX86Instances,

            binpackX86Instances,
            urlBinpackX86Instances: buildURLCheapestInstances(region.value, cheapeastBinpackX86Instances),
            priceBinpackX86Instances: priceBinpackX86Instances,

            likeForLikeGravitonInstances,
            urlLikeForLikeGravitonInstances: buildURLCheapestInstances(
              region.value,
              cheapestLikeForLikeGravitonInstances
            ),
            priceLikeForLikeGravitonInstances,

            binpackGravitonInstances,
            urlBinpackGravitonInstances: buildURLCheapestInstances(region.value, cheapestBinpackGravitonInstances),
            priceBinpackGravitonInstances,

            binpackMetalNonGravitonInstances,
            urlBinpackMetalNonGravitonInstances: buildURLCheapestInstances(
              region.value,
              cheapestBinpackMetalNonGravitonInstances
            ),
            priceBinpackMetalNonGravitonInstances: priceBinpackMetalNonGravitonInstances,
          },
          isValid: validateSheetFormat(sheetName, rawData),
          isNotEmpty: validateSheetIsNotEmpty(sheetName, rawData),
        };
      });

      if (sheetData.every(sheet => sheet.isEmpty)) {
        setSheets(sheetData);
        setError('');
      } else {
        setSheets([]);
        setError('Each tab must have <strong>only</strong> the following columns: ' + EXPECTED_COLUMNS.join(', '));
      }

      if (sheetData.every(sheet => sheet.isValid && sheet.isNotEmpty)) {
        setSheets(sheetData);
        setError('');
      } else {
        setSheets([]);
        if (!sheetData.every(sheet => sheet.isValid)) {
          setError(`Please ensure each tab has the following columns: ${EXPECTED_COLUMNS.join(', ')}`);
        } else if (!sheetData.every(sheet => sheet.isNotEmpty)) {
          setError('Empty tab(s). Please ensure each tab has data');
        } else {
          setError('Invalid spreadsheet: unknown error.');
        }
      }
    };

    reader.readAsBinaryString(file);
  };

  const renderTable = data => {
    if (data.length === 0) {
      return null;
    }

    return (
      <table>
        <thead>
          <tr>
            {data[0].map((header, index) => (
              <th key={`header-${Math.random().toString(36).substring(7)}-${index}`}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.slice(1).map((row, rowIndex) => (
            <tr key={`row-${Math.random().toString(36).substring(7)}-${rowIndex}`}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <SpaceBetween direction="vertical" size="xs">
        <Container>
          <ExpandableSection
            expanded
            onChange={() => {}}
            headerText="Step 1 - Select an AWS region. Import your inventory of OpenShift worker nodes."
          >
            {!showAdditionalComponent && (
              <Alert statusIconAriaLabel="Warning" type="warning" header="Data Privacy and Security Disclaimer">
                <br />
                By importing your data, you acknowledge and agree to the following:
                <ol>
                  <li key={`ack-${Math.random().toString(36).substring(7)}-0`}>
                    Data Responsibility: You are responsible for the data you upload. Please ensure it doesn't contain
                    any sensitive or confidential information.
                  </li>
                  <li key={`ack-${Math.random().toString(36).substring(7)}-1`}>
                    Data Handling: This calculator processes your input data client-side only. No information is stored
                    server-side.{' '}
                    <strong>
                      However, please be aware that all the data in the file you import is temporarily held in your
                      browser.
                    </strong>
                  </li>
                  <li key={`ack-${Math.random().toString(36).substring(7)}-2`}>
                    Data Validation: This calculator is designed to provide cost estimates based solely on the required
                    technical specifications (numerical values only), without the need for sensitive business
                    information. This limits the misuse of sensitive information (e.g., customer names, server names)
                    but doesn't prevent it entirely if you import files with sensitive data.{' '}
                  </li>
                </ol>
                <strong>Recommended Usage</strong>: we strongly recommend using our provided template and using generic
                names as Excel tab names. <br />
                You can view and download the template:{' '}
                <Link
                  href="https://docs.google.com/spreadsheets/d/1p9duRgZlhNZhtaykEILvlsR7L7RecyHm/edit?usp=sharing&ouid=111158409711486757406&rtpof=true&sd=true"
                  target="_blank"
                >
                  <Icon name="download" /> Template &gt; File &gt; Download Microsoft Excel (.xlsx)
                </Link>
                .
                <br />
                <br />
                By using this calculator, you also acknowledge that the results are for informational purposes.
                <br />
                You should consult the official website or contact the service provider directly for accurate pricing
                details.
                <br />
                <br />
                For any pricing related questions, please contact: &nbsp;
                <strong>
                  <a href="mailto:aws-redhat-partnerteam@amazon.com">aws-redhat-partnerteam@amazon.com</a>
                </strong>
                .
                <br />
                <br />
                This import feature is still <strong>experimental</strong>. If you find a bug or have any questions,
                please contact the{' '}
                <Link href="https://www.linkedin.com/in/wgarcia/" target="_blank">
                  author
                </Link>
                .
                <br />
                <br />
                <label>
                  <input type="checkbox" onChange={handleCheckboxConfirm} /> I have read and agree to the above
                  conditions.
                </label>
              </Alert>
            )}
            {showAdditionalComponent && (
              <SpaceBetween direction="vertical" size="xs">
                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 5 }]}>
                  <FormField
                    description="AWS region where ROSA will be deployed."
                    errorText={region.value === undefined ? 'No region selected.' : ''}
                    label="a. Select an AWS region"
                  >
                    <Select
                      data-testid="region-filter"
                      options={selectRegion}
                      selectedAriaLabel="Selected"
                      selectedOption={region}
                      onChange={event => {
                        setRegion(event.detail.selectedOption);
                      }}
                      ariaDescribedby={null}
                      expandToViewport={true}
                    />
                  </FormField>
                  <FormField
                    description="Upload a .xlsx file"
                    label="b. Upload your inventory data"
                    errorText={region.value === undefined ? 'You need to select a region before upload.' : ''}
                  >
                    <input disabled={!region.value} type="file" accept=".xlsx" onChange={handleFileUpload} />
                  </FormField>
                </Grid>
                <Alert
                  header={
                    <>
                      {' '}
                      <strong>View and download the import template to meet the input format:</strong>{' '}
                      <Link
                        href="https://docs.google.com/spreadsheets/d/1p9duRgZlhNZhtaykEILvlsR7L7RecyHm/edit?usp=sharing&ouid=111158409711486757406&rtpof=true&sd=true"
                        target="_blank"
                      >
                        <Icon name="download" /> Template &gt; File &gt; Download Microsoft Excel (.xlsx)
                      </Link>
                    </>
                  }
                >
                  <br />
                  File and data formatting rules:
                  <ul>
                    <li key={`format-${Math.random().toString(36).substring(7)}-0`}>
                      File format: <em>.xlsx</em> files only are accepted.
                    </li>
                    <li key={`format-${Math.random().toString(36).substring(7)}-1`}>
                      Excel tabs: one tab per cluster is required. No other are additional tabs accepted.
                    </li>
                    <li key={`format-${Math.random().toString(36).substring(7)}-2`}>
                      Server data: Remove <strong>all</strong> master and infrastructure nodes from the data. OCP / ROSA
                      Worker node data is needed only from your inventory. ROSA manages the control plane, so master and
                      infrastructure nodes are sized automatically by the service. If you keep them in your data, this
                      calculator will <strong>double-bill</strong> them.
                    </li>
                    <li key={`format-${Math.random().toString(36).substring(7)}-3`}>
                      Column headers: the exact following column names <strong>only</strong> are accepted:{' '}
                      {EXPECTED_COLUMNS.join(', ')}
                    </li>
                    <li key={`format-${Math.random().toString(36).substring(7)}-4`}>
                      Data format: % columns, required for usage data, must be formatted as cell type "Percentage" in
                      Excel.
                    </li>
                  </ul>
                </Alert>
                {error && (
                  <Alert statusIconAriaLabel="Error" type="error" header="Invalid spreadsheet format">
                    {error}
                  </Alert>
                )}
              </SpaceBetween>
            )}
          </ExpandableSection>
        </Container>

        {sheets.length > 0 && (
          <Container>
            <ExpandableSection
              expanded
              onChange={() => {}}
              key={`key-${Math.random().toString(36).substring(7)}`}
              headerText="Step 2 - Visualize, export / share the cost estimate(s)."
            >
              <Tabs
                tabs={sheets.map((sheet, index) => {
                  return {
                    label: sheet.name,
                    id: sheet.name,
                    content: (
                      <>
                        <SpaceBetween direction={'vertical'} size={'xs'}>
                          <Summary summary={sheet.summary} suggestions={sheet.suggestions} />
                          {sheet.countEntriesWithVCPULessThan4 > 0 && (
                            <Alert
                              type="warning"
                              statusIconAriaLabel="Warning"
                              header={'Minimum vCPUs per node not met in imported data'}
                            >
                              <br /> ROSA currently requires 4 vCPUs per worker at minimum due to billing but{' '}
                              <strong>{sheet.countEntriesWithVCPULessThan4} instances with &lt; 4 vCPUs</strong> were
                              found in the imported data.
                              <br />
                              <br /> In the ROSA options proposed above, nodes with less than 4 vCPUs are either:
                              <ol>
                                <li>grouped in a larger nodes in ROSA (binpack options).</li>
                                <li>or converted to a 4 vCPUs worker node in ROSA (like for like options).</li>
                              </ol>
                            </Alert>
                          )}

                          <Container>
                            <h2>Raw Data</h2>
                            <ExpandableSection
                              key={`section-imported-${index}`}
                              headerText={`Imported Excel Data (${cleanupExcelSheetData(sheet.data).length})`}
                            >
                              {renderTable(sheet.data)}
                            </ExpandableSection>
                            <ExpandableSection
                              key={`section-option-binpack-metalnongraviton-${index}`}
                              headerText={`${LABEL_SCENARIO_VIRTUALIZATION_NONGRAVITON} (${sheet.suggestions.binpackMetalNonGravitonInstances.suggestions.length})`}
                            >
                              <Suggestions suggestions={sheet.suggestions.binpackMetalNonGravitonInstances} />
                            </ExpandableSection>
                            <ExpandableSection
                              key={`section-option-likeforlike-${index}`}
                              headerText={`${LABEL_SCENARIO_LIKEFORLIKE_AMD64} (${sheet.suggestions.likeForLikeX86Instances.suggestions.length}) `}
                            >
                              <Suggestions suggestions={sheet.suggestions.likeForLikeX86Instances} />
                            </ExpandableSection>
                            <ExpandableSection
                              key={`section-option-binpack-x86-${index}`}
                              headerText={`${LABEL_SCENARIO_BINPACK_AMD64} (${sheet.suggestions.binpackX86Instances.suggestions.length})`}
                            >
                              <Suggestions suggestions={sheet.suggestions.binpackX86Instances} />
                            </ExpandableSection>
                            <ExpandableSection
                              key={`section-option-likeforlike-arm-${index}`}
                              headerText={`${LABEL_SCENARIO_LIKEFORLIKE_GRAVITON_NONMETAL} (${sheet.suggestions.likeForLikeGravitonInstances.suggestions.length})`}
                            >
                              <Suggestions suggestions={sheet.suggestions.likeForLikeGravitonInstances} />
                            </ExpandableSection>
                            <ExpandableSection
                              key={`section-option-binpack-arm-${index}`}
                              headerText={`${LABEL_SCENARIO_BINPACK_GRAVITON_NONMETAL} (${sheet.suggestions.binpackGravitonInstances.suggestions.length})`}
                            >
                              <Suggestions suggestions={sheet.suggestions.binpackGravitonInstances} />
                            </ExpandableSection>
                          </Container>
                        </SpaceBetween>
                      </>
                    ),
                  };
                })}
              />
            </ExpandableSection>
          </Container>
        )}
      </SpaceBetween>
    </>
  );
};

export default ExcelImporter;
