// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import { Header, HeaderProps, SpaceBetween } from '@cloudscape-design/components';

interface FullPageHeaderProps extends HeaderProps {
  title?: string;
  createButtonText?: string;
  extraActions?: React.ReactNode;
}

export function FullPageHeader({ title = 'Distributions', extraActions = null, ...props }: FullPageHeaderProps) {
  return (
    <Header
      variant="awsui-h1-sticky"
      actions={
        <SpaceBetween size="xs" direction="horizontal">
          {extraActions}
        </SpaceBetween>
      }
      {...props}
    >
      {title}
    </Header>
  );
}
