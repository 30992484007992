// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

const regionsFetch = async () => {
  try {
    const response = await fetch('data/regions.json');
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error loading JSON:', error);
    throw error;
  }
};

export const regions = await regionsFetch();
