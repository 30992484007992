// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import Table from '@cloudscape-design/components/table';
import Header from '@cloudscape-design/components/header';
import { StatusIndicator } from '@cloudscape-design/components';
import Icon from '@cloudscape-design/components/icon';
import Link from '@cloudscape-design/components/link';
import Popover from '@cloudscape-design/components/popover';

import {
  LABEL_SCENARIO_BINPACK_GRAVITON_NONMETAL,
  LABEL_SCENARIO_VIRTUALIZATION_NONGRAVITON,
  LABEL_SCENARIO_LIKEFORLIKE_AMD64,
  LABEL_SCENARIO_BINPACK_AMD64,
  LABEL_SCENARIO_LIKEFORLIKE_GRAVITON_NONMETAL,
} from '../constants';

function Summary(props) {
  const { summary, suggestions } = props;

  const items = [
    {
      label: 'Imported Data',
      category: '-',
      url: '-',
      workloadType: '-',
      help: '-',
      price: { onDemand1Y: '-', savingsPlanComputeNoUpfront1y: '-', savingsPlanComputeNoUpfront3y: '-' },
      vcpus: {
        vcpuSpec: `${summary.totalVCPUs.toLocaleString()}`,
        vcpuUsed: `${summary.avgVCPUs.toLocaleString()}`,
        vcpuRatio: (summary.totalVCPUs / summary.avgVCPUs).toFixed(1),
      },
      mem: {
        memSpec: `${summary.totalMemory.toLocaleString()}`,
        memUsed: `${summary.avgMemory.toLocaleString()}`,
        memRatio: (summary.totalMemory / summary.avgMemory).toFixed(1),
      },
      storage: {
        storageSpec: `${summary.totalStorage.toLocaleString()} GB`,
        storageUsed: `${summary.avgStorage.toLocaleString()} GB`,
        storageRatio: (summary.totalStorage / summary.avgStorage).toFixed(1),
      },
    },
  ];

  function buildItemSuggestion(label, suggestion, url, price, help, workloadType = '') {
    const vcpuRatio = Number(suggestion.totalSuggestedVCPUs / summary.avgVCPUs);
    const memRatio = Number(suggestion.totalSuggestedMemory / summary.avgMemory);
    const storageRatio = Number(suggestion.totalSuggestedStorage / summary.avgStorage);
    const score = vcpuRatio + memRatio + storageRatio;

    return {
      label,
      score,
      url,
      price,
      help,
      category: '-',
      workloadType: workloadType !== '' ? workloadType : '-',
      vcpus: {
        vcpuSpec: `${summary.avgVCPUs.toLocaleString()}`,
        vcpuUsed: `${suggestion.totalSuggestedVCPUs.toLocaleString()}`,
        vcpuRatio: vcpuRatio.toFixed(1),
      },
      mem: {
        memSpec: `${summary.avgMemory.toLocaleString()}`,
        memUsed: `${suggestion.totalSuggestedMemory.toLocaleString()}`,
        memRatio: memRatio.toFixed(1),
      },
      storage: {
        storageSpec: `${summary.avgStorage.toLocaleString()} GB`,
        storageUsed: `${suggestion.totalSuggestedStorage.toLocaleString()} GB`,
        storageRatio: storageRatio.toFixed(1),
      },
    };
  }

  items.push(
    buildItemSuggestion(
      LABEL_SCENARIO_VIRTUALIZATION_NONGRAVITON,
      suggestions.binpackMetalNonGravitonInstances,
      suggestions.urlBinpackMetalNonGravitonInstances,
      suggestions.priceBinpackMetalNonGravitonInstances,
      <>
        <strong>Pros:</strong>
        <br />
        - Allows running both containers and VMs on the same platform
        <br />
        - Easier migration path for workloads that can't be containerized
        <br />
        - Provides a bridge between traditional and cloud-native architectures
        <br />
        - Leverages OpenShift's management capabilities for both containers and VMs
        <br />
        <br />
        <strong>Cons:</strong>
        <br />
        - Higher costs compared to pure container deployments
        <br />- Not a full transition to containerized, cloud-native architecture
      </>,
      'Virtual Machines in ROSA'
    )
  );

  const scenarios = [];
  scenarios.push(
    buildItemSuggestion(
      LABEL_SCENARIO_LIKEFORLIKE_AMD64,
      suggestions.likeForLikeX86Instances,
      suggestions.urlLikeForLikeX86Instances,
      suggestions.priceLikeForLikeX86Instances,
      <>
        <strong>Pros:</strong>
        <br />
        - The imported number of worker nodes is preserved, so workloads will maintain their current node placement
        strategy in ROSA. <br />
        - Simplest migration path with minimal changes to existing architecture <br />
        - Familiar performance characteristics and compatibility <br />
        - Easier capacity planning based on current infrastructure <br />
        - Minimal retraining required for operations team
        <br />
        <br />
        <strong>Cons:</strong>
        <br />- The vCPU to Memory ratios on AWS can differ compared to non AWS environments, potentially resulting in
        larger VM specifications on AWS and therefore a higher cost. <br />- May not take full advantage of cloud
        efficiencies (autoscaling and flexible pricing) <br />- Potentially higher costs if current infrastructure is
        already overprovisioned <br />- Missed opportunity for optimization and modernization{' '}
      </>
    )
  );
  scenarios.push(
    buildItemSuggestion(
      LABEL_SCENARIO_BINPACK_AMD64,
      suggestions.binpackX86Instances,
      suggestions.urlBinpackX86Instances,
      suggestions.priceBinpackX86Instances,
      <>
        <strong>Pros:</strong>
        <br />
        - Less nodes, potentially reducing costs for 3rd party software that uses server-based licenses
        <br />
        - Better resource utilization, potentially reducing costs
        <br />
        - Improved performance by optimizing workload placement
        <br />
        - Takes advantage of cloud flexibility and scalability
        <br />
        - Opportunity to right-size the infrastructure
        <br />
        <br />
        <strong>Cons:</strong>
        <br />- May need detailed analysis of workload requirements
      </>
    )
  );

  scenarios.push(
    buildItemSuggestion(
      LABEL_SCENARIO_LIKEFORLIKE_GRAVITON_NONMETAL,
      suggestions.likeForLikeGravitonInstances,
      suggestions.urlLikeForLikeGravitonInstances,
      suggestions.priceLikeForLikeGravitonInstances,
      <>
        <strong>Pros:</strong>
        <br />
        - Potential for significant cost savings due to Graviton's price-performance benefits Improved energy efficiency
        <br />
        - Access to latest ARM-based technology Native integration with AWS services
        <br />
        <br />
        <strong>Cons:</strong>
        <br />
        - Requires recompilation of applications for ARM architecture Potential compatibility issues with some software
        <br />- Learning curve for operations team unfamiliar with ARM May require extensive testing and validation
      </>
    )
  );
  scenarios.push(
    buildItemSuggestion(
      LABEL_SCENARIO_BINPACK_GRAVITON_NONMETAL,
      suggestions.binpackGravitonInstances,
      suggestions.urlBinpackGravitonInstances,
      suggestions.priceBinpackGravitonInstances,
      <>
        <strong>Pros:</strong>
        <br />
        - Less nodes, potentially reducing costs for 3rd party software that uses server-based licenses
        <br />
        - Combines benefits of binpacking and Graviton architecture
        <br />
        - Highest potential for cost optimization and performance improvements
        <br />
        - Maximizes cloud-native benefits and AWS integration
        <br />
        - Future-proofs infrastructure with latest technology
        <br />
        - The transition to Graviton can be gradually done in ROSA
        <br />
        <br />
        <strong>Cons:</strong>
        <br />
        - Requires planning, testing, and potential application modifications
        <br />
        - Highest risk of compatibility issues
        <br />
        - May require retraining and process changes
        <br />
      </>
    )
  );

  function generateRecommendations(data) {
    // Sort data by score
    const sortedData = data.sort((a, b) => a.score - b.score);

    return sortedData.map(scenario => ({
      ...scenario, // Preserve all original fields
      category: getRecommendationCategory(scenario, sortedData),
    }));
  }

  function getRecommendationCategory(scenario, allScenarios) {
    if (scenario.score === allScenarios[0].score) {
      return (
        <>
          <StatusIndicator type={'success'}>Best ROSA option</StatusIndicator>
        </>
      );
    } else if (scenario.score === allScenarios[allScenarios.length - 1].score) {
      return (
        <>
          <StatusIndicator type={'error'}>Least favorable ROSA option</StatusIndicator>
        </>
      );
    } else {
      const medianScore = allScenarios[Math.floor(allScenarios.length / 2)].score;
      return scenario.score <= medianScore ? (
        <>
          <StatusIndicator type={'success'}>Above average ROSA option</StatusIndicator>
        </>
      ) : (
        <>
          <StatusIndicator type={'warning'}>Below average ROSA option</StatusIndicator>
        </>
      );
    }
  }

  const recommendations = generateRecommendations(scenarios);
  for (const recommendation of recommendations) {
    items.push(recommendation);
  }

  return (
    <>
      <Table
        header={<Header>Summary of findings</Header>}
        pa
        columnDefinitions={[
          {
            id: 'label',
            header: 'Option',
            width: 280,
            cell: item => (
              <>
                <strong>{item.label}</strong>{' '}
                {item.help !== '-' && (
                  <Popover size="large" dismissButton={false} position="top" triggerType="custom" content={item.help}>
                    <Icon name="status-info" />
                  </Popover>
                )}
                <br />
                {item.category !== '-' && (
                  <>
                    <br />
                    {item.category}
                  </>
                )}
                {item.url !== '-' && (
                  <>
                    <br />
                    <Icon name="external" />{' '}
                    <Link href={`${item.url}`} target={'_blank'}>
                      Cost estimate
                    </Link>
                  </>
                )}
                {(item.vcpus.vcpuRatio > 1 || item.mem.memRatio > 1 || item.storage.storageRatio > 1) && (
                  <>
                    <br />
                    {item.vcpus.vcpuRatio > 1 && (
                      <>
                        <br />
                        <Icon name="filter" /> vCPUs in excess: x{item.vcpus.vcpuRatio}
                      </>
                    )}
                    {item.mem.memRatio > 1 && (
                      <>
                        <br />
                        <Icon name="filter" /> Memory in excess x{item.mem.memRatio}
                      </>
                    )}
                    {item.storage.storageRatio > 1 && (
                      <>
                        <br />
                        <Icon name="filter" /> Storage Oversized x{item.storage.storageRatio}
                      </>
                    )}
                  </>
                )}
                {item.workloadType !== '-' && (
                  <>
                    <br />
                    <br />
                    <em>Use case: {item.workloadType}</em>
                  </>
                )}
              </>
            ),
          },
          {
            id: 'price',
            header: 'Price with ROSA HCP',
            cell: item => (
              <>
                {item.price.onDemand1Y !== '-' && (
                  <>
                    On-Demand 1Y: $
                    {Math.round(item.price.onDemand1Y).toLocaleString('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    <br />
                    Compute Savings Plans 1Y: $
                    {item.price.savingsPlanComputeNoUpfront1y.toLocaleString('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    <br />
                    Compute Savings Plans 3Y: $
                    {item.price.savingsPlanComputeNoUpfront3y.toLocaleString('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </>
                )}
              </>
            ),
          },
          {
            id: 'vcpus',
            header: 'vCPUs',
            cell: item => (
              <>
                vCPUs Specifications: {item.vcpus.vcpuSpec}
                <br />
                vCPUs Required: {item.vcpus.vcpuUsed}
              </>
            ),
          },
          {
            id: 'memory',
            header: 'Memory',
            cell: item => (
              <>
                Memory Specifications: {item.mem.memSpec}
                <br />
                Memory Required: {item.mem.memUsed}
              </>
            ),
          },
          {
            id: 'storage',
            header: 'Storage',
            cell: item => (
              <>
                Storage Specifications: {item.storage.storageSpec}
                <br />
                Storage Required: {item.storage.storageUsed}
              </>
            ),
          },
        ]}
        items={items}
        wrapLines={true}
      />
    </>
  );
}

export default Summary;
