// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from 'react';
import Table from '@cloudscape-design/components/table';

function Suggestions(props) {
  const { suggestions } = props;

  return (
    <>
      <Table
        columnDefinitions={[
          { id: 'vpcu', header: 'Suggested vCPU (Count)', cell: item => item.vcpu, sortingField: 'alt' },
          {
            id: 'memory',
            header: 'Suggested Memory (GiB)',
            cell: item => item.memory,
          },
          {
            id: 'storage',
            header: 'Suggested Storage (GB)',
            cell: item => item.storage,
          },
        ]}
        items={suggestions.suggestions}
      />
    </>
  );
}

export default Suggestions;
