// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import React, { useRef, useLayoutEffect, useState } from 'react';
import { CustomAppLayout, Navigation } from './pages/commons/common-components';
import { TableSelectFilter } from './pages/table-select-filter';
import './styles/base.scss';
import { info } from './data/info';
import Cards from '@cloudscape-design/components/cards';
import ReactPlayer from 'react-player';
import Flashbar from '@cloudscape-design/components/flashbar';
import Tabs from '@cloudscape-design/components/tabs';
import ExcelImporter from './pages/import/ExcelImporter';
import { FullPageHeader } from './pages/commons';
import Link from '@cloudscape-design/components/link';
import Table from '@cloudscape-design/components/table';
import Header from '@cloudscape-design/components/header';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SpaceBetween } from '@cloudscape-design/components';

try {
  // const config = {
  //   sessionSampleRate: 1,
  //   guestRoleArn: 'arn:aws:iam::495538033277:role/RUM-Monitor-ap-southeast-2-495538033277-0430747151171-Unauth',
  //   identityPoolId: 'ap-southeast-2:6a090fad-db1c-4c33-ab38-0ad356ec0ac7',
  //   endpoint: 'https://dataplane.rum.ap-southeast-2.amazonaws.com',
  //   telemetries: ['performance', 'errors', 'http'],
  //   allowCookies: true,
  //   enableXRay: false,
  // };
  // const APPLICATION_ID = 'f2237a43-2401-47d1-8e4d-5bca83fd25ea';
  // const APPLICATION_VERSION = '1.0.0';
  // const APPLICATION_REGION = 'ap-southeast-2';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

const programItems = [
  {
    program: (
      <Link href="https://docs.aws.amazon.com/marketplace/latest/buyerguide/buyer-private-offers.html" target="_blank">
        AWS Marketplace Private Offers Discount
      </Link>
    ),
    description: (
      <>
        The AWS Marketplace seller private offer feature enables you to receive product pricing and EULA terms from a
        seller that aren't publicly available. You negotiate pricing and terms with the seller, and the seller creates a
        private offer for the AWS account that you designate. You accept the private offer and start receiving the
        negotiated price and terms of use.
      </>
    ),
    eligibility: (
      <>
        Issued by Red Hat or AWS Partner eligible for{' '}
        <Link href="https://aws.amazon.com/marketplace/features/cpprivateoffers">
          Channel Partner Private Offers (CPPO)
        </Link>
      </>
    ),
  },
  {
    program: 'AWS and Red Hat PoC Credits Discount',
    description:
      'Partner Opportunity Acceleration (POA) funding for POC & Migrations—cash reimbursement, AWS promotional credits',
    eligibility: (
      <>
        Contact{' '}
        <strong>
          <a href="mailto:aws-redhat-partnerteam@amazon.com">aws-redhat-partnerteam@amazon.com</a>
        </strong>
      </>
    ),
  },
  {
    program: (
      <Link href="https://aws.amazon.com/rosa/pricing/" target="_blank">
        Discounts for existing Red Hat OpenShift and IBM customers
      </Link>
    ),
    description:
      'Customers migrating non-ROSA OpenShift workloads to ROSA are eligible for a 75% discount off the ROSA service fee 1-year contract rate over the remaining term of their non-ROSA OpenShift subscriptions.',
    eligibility: (
      <>
        Contact{' '}
        <strong>
          <a href="mailto:aws-redhat-partnerteam@amazon.com">aws-redhat-partnerteam@amazon.com</a>
        </strong>
      </>
    ),
  },
  {
    program: (
      <Link href="https://aws.amazon.com/migration-acceleration-program/" target="_blank">
        AWS Migration Acceleration Program (MAP)
      </Link>
    ),
    description: (
      <>
        Migration Acceleration Program (MAP) funds support you with migrations or modernizations of any size or workload
        to AWS, at each phase of the customer’s migration journey.
        <br /> Receive cash or AWS promotional credits to originate opportunities, accelerate customer migration or
        modernizations workloads to AWS and grow your Migration practices on AWS.
      </>
    ),
    eligibility: (
      <>
        Contact{' '}
        <strong>
          <a href="mailto:aws-redhat-partnerteam@amazon.com">aws-redhat-partnerteam@amazon.com</a>
        </strong>
      </>
    ),
  },

  {
    program: (
      <Link href="https://aws.amazon.com/vmware/migrationaccelerator/" target="_blank">
        AWS VMware Migration Accelerator
      </Link>
    ),
    description: (
      <>
        Until December 31, 2024, VMware Cloud on AWS customers can earn AWS promotional credits for migrating virtual
        machines (VMs) to Amazon EC2.
        <ul>
          <li>Migrate 80+ VMs in a month, get up to $400 credit per migrated VM</li>
          <li>Migrate 40 to 79 VMs in a month, get up to $200 credit per migrated VM</li>
        </ul>
      </>
    ),
    eligibility:
      'To qualify, each migrated VM must run on AWS for at least 720 hours after migration. VMware Migration Accelerator incentives are not applicable to Amazon EC2 T2 and T3 instances.',
  },

  {
    program: (
      <Link href="https://aws.amazon.com/application-migration-service/windows/" target="_blank">
        AWS Windows Migration Accelerator
      </Link>
    ),
    description: (
      <>
        Save costs with AWS Promotional Credits when you accelerate migrations of Windows workloads to AWS. Until
        December 31, 2024, customers using Application Migration Service to migrate:
        <ul>
          <li>
            At least 40 servers to AWS in one month - including a minimum of 15 Windows servers - may be eligible to
            receive a $200 AWS Promotional Credit per Windows server.{' '}
          </li>
          <li>
            If you migrate more than 80 servers — including at least 25 Windows servers — in a calendar month, we will
            increase the discount to $250 AWS Promotional Credit for each Windows server you migrate to AWS using AWS
            Application Migration Service.
          </li>
        </ul>
      </>
    ),
    eligibility: (
      <>
        Contact{' '}
        <strong>
          <a href="mailto:WMA-MGN@amazon.com">WMA-MGN@amazon.com</a>
        </strong>
      </>
    ),
  },
  {
    program: (
      <Link href="https://aws.amazon.com/partners/funding/" target="_blank">
        AWS Partner Network (APN) Innovation Sandbox Credits
      </Link>
    ),
    description: (
      <>
        Offset AWS usage costs incurred during solution development with AWS Promotional Credits as part of the
        Innovation Sandbox program.
        <br />
        AWS Partners can integrate AWS services into offerings and launch solutions built on AWS, faster and more
        efficiently.
      </>
    ),
    eligibility: (
      <>
        Contact{' '}
        <strong>
          <a href="mailto:aws-redhat-partnerteam@amazon.com">aws-redhat-partnerteam@amazon.com</a>
        </strong>
      </>
    ),
  },
];
const Main = () => {
  const appLayout = useRef();

  const date = new Date(info.last_updated * 1000);
  const [showFlashbar, setShowFlashbar] = useState(true);
  const [flashbarItems, setFlashbarItems] = React.useState([
    {
      type: 'info',
      dismissible: true,
      dismissLabel: 'Dismiss message',
      onDismiss: () => setFlashbarItems([]),
      content: (
        <>
          ROSA now supports Graviton instances. Learn more:&nbsp;
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/g5g/"
            target="_blank"
            rel="noreferrer"
          >
            G5g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/t4/"
            target="_blank"
            rel="noreferrer"
          >
            T4g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/c6g/"
            target="_blank"
            rel="noreferrer"
          >
            C6g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/c7g/"
            target="_blank"
            rel="noreferrer"
          >
            C7g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/hpc7g/"
            target="_blank"
            rel="noreferrer"
          >
            Hpc7g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/m6g/"
            target="_blank"
            rel="noreferrer"
          >
            M6g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/m7g/"
            target="_blank"
            rel="noreferrer"
          >
            M7g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/r6g/"
            target="_blank"
            rel="noreferrer"
          >
            R6g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/r7g/"
            target="_blank"
            rel="noreferrer"
          >
            R7g
          </a>
          ,&nbsp;{' '}
          <a
            style={{ color: '#FFF' }}
            href="https://aws.amazon.com/ec2/instance-types/i4g/"
            target="_blank"
            rel="noreferrer"
          >
            I4g
          </a>
          <br />
          <br />
          Note: Graviton is available for <strong>ROSA HCP (Hosted Control Planes) clusters only</strong>. Watch{' '}
          <a
            style={{ color: '#FFF' }}
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/watch?v=qNRZhc4SSPs"
          >
            {' '}
            Migrating from ROSA classic to HCP
          </a>{' '}
          if you are currently using ROSA classic.
        </>
      ),
      id: 'message_1',
    },
  ]);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setShowFlashbar(false);
    }, 5000);
    return () => clearTimeout(timer);
  });

  return (
    <>
      <CustomAppLayout
        ref={appLayout}
        navigation={<Navigation activeHref="#/instances" />}
        content={
          <>
            <SpaceBetween direction="vertical" size="xs">
              {showFlashbar && <Flashbar items={flashbarItems} />}
              <FullPageHeader title="Red Hat OpenShift offerings on AWS - Pricing calculator" />
              <Tabs
                tabs={[
                  {
                    label: 'Single cluster Inventory (ROSA and OCP)',
                    id: 'first',
                    content: (
                      <TableSelectFilter
                        loadHelpPanelContent={() => {
                          appLayout.current?.focusToolsClose();
                        }}
                        height={50}
                      />
                    ),
                  },
                  {
                    label: 'Multi cluster Inventory Import (ROSA)',
                    id: 'second',
                    content: <ExcelImporter />,
                  },
                  {
                    label: 'AWS Programs / Savings',
                    id: 'programs',
                    content: (
                      <>
                        <Table
                          header={<Header>AWS Programs ROSA is eligible for </Header>}
                          pa
                          columnDefinitions={[
                            {
                              id: 'program',
                              header: 'Name',
                              cell: item => item.program,
                            },
                            {
                              id: 'description',
                              header: 'Description',
                              cell: item => item.description,
                            },
                            {
                              id: 'eligibility',
                              header: 'Eligibility',
                              cell: item => item.eligibility,
                            },
                          ]}
                          items={programItems}
                          wrapLines={true}
                        />
                      </>
                    ),
                  },
                  {
                    label: 'Get Started Resources',
                    id: 'resources',
                    content: (
                      <Cards
                        style={{ margin: '20px' }}
                        ariaLabels={{
                          itemSelectionLabel: (e, i) => `select ${i.name}`,
                        }}
                        cardDefinition={{
                          header: item => item.name,
                          sections: [
                            {
                              id: 'description',
                              content: item => item.description,
                            },
                          ],
                        }}
                        cardsPerRow={[{ cards: 1 }, { minWidth: 300, cards: 2 }]}
                        items={[
                          {
                            name: 'Cost Savings in ROSA',
                            alt: 'First',
                            description: (
                              <ReactPlayer controls={true} url="https://www.youtube.com/watch?v=QkvHYXgvSwo" />
                            ),
                          },
                          {
                            name: 'Apply for a Proof-of-Concept environment',
                            alt: 'Second',
                            description: (
                              <ReactPlayer controls={true} url="https://www.youtube.com/watch?v=4-bUVwN8xX8" />
                            ),
                          },
                          {
                            name: 'ROSA Private Offers',
                            alt: 'Third',
                            description: (
                              <ReactPlayer controls={true} url="https://www.youtube.com/watch?v=K0OGlpYMZEo" />
                            ),
                          },
                          {
                            name: 'VMware to ROSA Virtualization',
                            alt: 'Third',
                            description: (
                              <ReactPlayer controls={true} url="https://www.youtube.com/watch?v=DKjucYGg8dI" />
                            ),
                          },
                          {
                            name: 'OpenShift Migrations permutations',
                            alt: 'Third',
                            description: (
                              <ReactPlayer controls={true} url="https://youtu.be/g7cUJyTDIac?si=bXOG8-_EhsTRgYkG" />
                            ),
                          },

                          {
                            name: 'From ROSA Classic to HCP',
                            alt: 'Third',
                            description: (
                              <ReactPlayer controls={true} url="https://youtu.be/qNRZhc4SSPs?si=OeWLWtS8nhn83Eai" />
                            ),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
              />
              <small style={{ float: 'right', margin: '0 20px' }}>&nbsp;</small>

              <small style={{ float: 'right', margin: '0 20px' }}>
                Prices updated on {date.getMonth() + 1}/{date.getDate()}/{date.getFullYear()} (at {date.getHours()}:
                {date.getMinutes()})
              </small>
            </SpaceBetween>
          </>
        }
        contentType="table"
      />
    </>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Main />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
