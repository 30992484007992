// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
interface EC2Instance {
  type: string;
  priceOnDemand: string;
  vcpu: string;
  memory: string;
}

interface Suggestion {
  vcpu: number;
  memory: number;
  storage: number;
}

export function buildURLCheapestInstances(
  regionCode: string,
  instanceCounts: { [s: string]: unknown } | ArrayLike<unknown>
): string {
  const nodes = Object.entries(instanceCounts).map(([type, count]) => ({ type, count }));
  const urlParams = new URLSearchParams();
  urlParams.append('region', regionCode);
  urlParams.append('nodes', JSON.stringify(nodes));

  return `?${urlParams.toString()}`;
}

export function getCheapestInstances(ec2Prices: EC2Instance[], suggestions: Suggestion[]) {
  const instanceCounts: { [key: string]: number } = {};

  suggestions.forEach(suggestion => {
    const matchingInstances = ec2Prices.filter(instance => {
      const instanceVCPU = parseInt(instance.vcpu);
      const instanceMemory = parseInt(instance.memory);
      return instanceVCPU === suggestion.vcpu && instanceMemory === suggestion.memory;
    });

    if (matchingInstances.length > 0) {
      const cheapestInstance = matchingInstances.reduce((prev, current) =>
        parseFloat(prev.priceOnDemand) < parseFloat(current.priceOnDemand) ? prev : current
      );

      instanceCounts[cheapestInstance.type] = (instanceCounts[cheapestInstance.type] || 0) + 1;
    }
  });
  return instanceCounts;
}
